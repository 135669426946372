<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 60%" @submit.prevent="save">
        <b-row>
         
            <b-col cols="12">
              <b-form-group
                label="Contact Form"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-input v-model="addCourseForm.contact_form" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Newsletter Form"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
                   <b-form-input v-model="addCourseForm.newsletter_form" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
             <b-col cols="12">
              <b-form-group
                label="Registration Form"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-input v-model="addCourseForm.registration_form" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
            
              <b-form-group
                label="Live chat widget"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
                  <b-form-input v-model="addCourseForm.live_chat_widget" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
         

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
         
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";

export default {
 setup() {
     const form=ref({
     contact_form:'',
newsletter_form:'',

registration_form:'',
live_chat_widget:''
     })
const addCourseForm =reactive(form)
    let data=['contact_form',
'newsletter_form',
'registration_form','live_chat_widget']
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
     store.dispatch("setting/GetSettingArray", formData).then((response) => {
  console.log("data",response)
  // form.value=response.data
  form.value.contact_form=response.data[0].value
   form.value.newsletter_form=response.data[1].value
     form.value.registration_form=response.data[2].value
   form.value.live_chat_widget=response.data[3].value
    });
    const addCourseFormvalidate = ref();
   

    const save = () => {
      console.log(addCourseForm);
      const formData = new FormData();
  
      let data=[{
        name:'contact_form',
        value:addCourseForm.value.contact_form
      },
      {
        name:'newsletter_form',
         value:addCourseForm.value.newsletter_form
      },{
        name:'registration_form',
        value:addCourseForm.value.registration_form
      },
      {
        name:'live_chat_widget',
         value:addCourseForm.value.live_chat_widget
      }
      
      ]
    
      formData.append("data", JSON.stringify(data));

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          
          store
            .dispatch("setting/updateSettingArray", formData)
            .then((response) => {
              Vue.swal({
                title: "Updated",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

          console.log("alt_header_image", addCourseForm);
        }
      });
    };
   
    return {
     
  
      addCourseForm,
      form,
  
      addCourseFormvalidate,
  

      save,
    
    };
  },

  components: {
    BCard,
    BMedia,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
  .col-12 {
    margin: 0px !important;
  }
  
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
